export const siteConfig = {
  "name": "WorldGuessr Unlimited",
  "slogan": "slogan",
  "domain": "worldguessr.world",
  "icon": "/icon.png",
  "appleIcon": "/apple-touch-icon.png",
  "theme": {
    "headFont": null,
    "primaryColor": null,
    "fontColor": null,
    "backgroundColor": null
  },
  "gaId": "G-R2EXP2Q42H",
  "plausible": "https://app.pageview.app/js/script.js",
  "navbarItems": [
    {
      "title": "home",
      "href": "/"
    },
    {
      "title": "guide",
      "href": "/guide"
    },
    {
      "title": "information",
      "href": "/t"
    }
  ],
  "gameType": "iframe",
  "gameIframeUrl": "https://game.worldguessr.world",
  "gameDownloadBg": "",
  "gameDownloadUrl": "",
  "isShowFAQs": true,
  "isShowVideo": true,
  "isShowGuide": true,
  "videos": [
    {
      "title": "I Speedran Worldguessr...",
      "url": "https://www.youtube.com/watch?v=TKBjXG2gcUM"
    },
    {
      "title": "Main Worldguessr | Game saingan geoguessr",
      "url": "https://www.youtube.com/watch?v=slWaoRN-8eA"
    },
    {
      "title": "WorldGuessr Gameplay",
      "url": "https://www.youtube.com/watch?v=m-tlc2gyxUI"
    }
  ],
  "isShowComments": true,
  "isShowTweets": false,
  "useRealTweets": null,
  "tweets": [
    "https://x.com/KrytenSH1/status/1850302342627029308",
    "https://x.com/textualdeviance/status/451888307639554049",
    "https://x.com/mikewlivin/status/399547135081062401",
    "https://x.com/textualdeviance/status/451233848349585408"
  ],
  "comments": [
    {
      "author": "",
      "role": "",
      "content": "",
      "avatar": ""
    }
  ],
  "isShowRecommendation": true,
  "friendLinks": [
    {
      "title": "",
      "url": ""
    }
  ],
  "recommendations": {
    "en": [
      {
        "title": "City Guessr",
        "cover": "/cache/data/image/game/screenshot_10-m200x134.jpg",
        "url": "https://geoguessrfree.com/city-guesser.embed"
      }
    ]
  },
  "faviconBgColor": null,
  "faviconTextColor": null,
  "faviconText": "WO"
};
